import { useQueryClient } from '@tanstack/vue-query';
import cloneDeep from 'lodash-es/cloneDeep';
import { movieCacheKeys, movieFavoriteParamUpdKey } from '~/consts/movieCacheKeys';
import type { IMovie } from '~/types/movies';

export default function useMovieCacheUpdater() {
  const queryClient = useQueryClient();

  const updateMovieCache = (queryKey: string[], movieId: number, updatedMovie: IMovie) => {
    queryClient.setQueryData(queryKey, (oldData) => {
      const pages: {
        results: IMovie[];
      } = cloneDeep(oldData.pages);

      pages.forEach(page => {
        const updatingMovieIndex = page.results.findIndex(movie => movie.id === movieId);

        if (updatingMovieIndex !== -1) {
          page.results[updatingMovieIndex] = {
            ...page.results[updatingMovieIndex],
            ...updatedMovie,
          };
        }
      });

      return { ...oldData, pages };
    });
  };

  const updateMoviesFavoriteParam = (movieId: number, newValue: boolean) => {
    queryClient.invalidateQueries({ queryKey: movieCacheKeys.userFavoriteMovies });

    queryClient.getQueryCache()
      .findAll({ predicate: query => query.queryKey.includes(movieFavoriteParamUpdKey) })
      .forEach(query => updateMovieCache(query.queryKey, movieId, { favorite: newValue }))
  };

  return {
    updateMoviesFavoriteParam,
  };
};